import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import Meta from '../shared/Meta';

// Selectors
import { getCityConfig } from '../../selectors';

class ChooseAccount extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { cityConfig } = this.props;

    return (
      <div className="NewSection u-textCenter">
        <Meta noindex />

        <h1 className="u-mb2">Sign Up</h1>

        <div className="Box u-p2 Box--white">
          <h3 className="u-mb1">
            I'm a Food {cityConfig.vehicle_type} Vendor
          </h3>
          <p className="u-limitWidth20 u-blockCenter">
            Join {cityConfig.city_name}'s food {cityConfig.vehicle_type.toLowerCase()} community of over 300 mobile vendors and growing!
          </p>
          <Link
            className="Button"
            to="/vendors"
          >
            Sign up as a Vendor
          </Link>
        </div>

        <h3 className="u-color-grey u-upperCase u-mt1 u-mb1">or</h3>

        <div className="Box u-p2 Box--white">
          <h3 className="u-mb1">
            I Want to Book a {cityConfig.vehicle_type}
          </h3>
          <p className="u-limitWidth20 u-blockCenter">
            Hire and compare prices on {cityConfig.city_name}'s best food {cityConfig.vehicle_type.toLowerCase()}s for your next event!
          </p>
          <Link
            className="Button"
            to="catering/register"
          >
            Sign up to book {cityConfig.vehicle_type.toLowerCase()}s
          </Link>
        </div>

        <div className="u-textCenter">
          <hr className="u-mb3 u-mt3" />
          <h3 className="u-mb1">Already Have an Account?</h3>
          <Link
            to="/login"
            className="Button"
          >
            Log In
          </Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

ChooseAccount.propTypes = {
  auth: PropTypes.object,
  route: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseAccount);
